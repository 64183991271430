@layer components {
  .textarea {
    --tw-border-opacity: 0;
    @apply w-full p-0;

    textarea {
      @apply h-full w-full flex-shrink bg-light px-4 text-base leading-6 min-h-12;
      vertical-align: top;
      border-radius: var(--rounded-btn, .5rem);
    }

    &:has(.picker-input-prefix) input {
      @apply pl-10
    }

    &:has(.picker-input-suffix) input {
      @apply pr-10
    }

    .picker-input-prefix, .picker-input-suffix {
      @apply absolute inset-y-0 flex items-center;
    }

    .picker-input-prefix {
      @apply left-0;
    }

    .picker-input-suffix {
      @apply right-0;
    }

    .picker-input-prefix, .picker-input-suffix {
      span, label {
        @apply px-3;
      }

      .info {
        @apply mx-2;
      }
    }
  }
}

@layer utilities {
  .textarea-bordered {
    --tw-border-opacity: .2;
    @apply input-bordered;
  }

  .textarea-error {
    input {
      @apply border-error;
    }

    .label-text, .label-text-alt {
      @apply text-error;
    }
  }
}