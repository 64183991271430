.btn {
  &.btn-light {
    @apply bg-base-100 border-0;

    &:hover {
      @apply bg-slate-100;
    }
  }
  &.btn-disabled, &[disabled], &:disabled {
    @apply text-base-200;
    @apply bg-gray-300;
  }
}