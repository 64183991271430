.dropdown {
  .dropdown-content {
    overflow: scroll;
    display: block;
  }
}

.universityEditor {
  .dropdown {
    display: block;
    .dropdown-content {
      max-height: 1150%;
    }
  }
}
