.bubble {
  @apply flex flex-col items-center relative;
  min-width: 140px;
  max-width: 165px;
  min-height: 140px;

  .bubble-counter, .bubble-overlay {
    @apply flex justify-center items-center bg-light text-2xl transition-all text-center;
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }

  .bubble-overlay {
    @apply absolute bg-base-300 text-white text-base opacity-0 cursor-pointer;
  }

  .bubble-sub-text {
    @apply text-center font-medium transition-all opacity-100;
  }

  &:hover {
    .bubble-overlay {
      width: 140px;
      height: 140px;
      @apply opacity-100;
    }

    .bubble-sub-text {
      @apply opacity-0;
    }
  }
}