html, body, #root {
  font-family: "Poppins", serif;
  @apply h-full w-full antialiased;
}

details[open] {
  background: hsl(var(--b1));
}

.tooltip:after, .tooltip:before {
  @apply shadow-xl z-50 bg-light;
}

.infinite-scroll-component__outerdiv {
  @apply h-full;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

#webpack-dev-server-client-overlay-div {
  display: none !important;
}

.grid-cols-4 > *:nth-child(4n).datagroup .karls-datagroup-end {
  right: 0;
}

@layer base {
  :root {
    --color-marine: theme(colors.marine);
  }
  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }
}