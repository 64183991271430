@import "tailwind";
@import "fonts/poppins";
@import "fonts/nerd";
@import "react-loading-skeleton/dist/skeleton.css";

// Base
@import "base/base";

// Components
@import "components/bubble";
@import "components/documentExport";
@import "components/button";
@import "components/checkbox";
@import "components/details";
@import "components/dropdown";
@import "components/select";
@import "components/info";
@import "components/input";
@import "components/steps";
@import "components/table";
@import "components/textarea";
@import "components/tooltip";
