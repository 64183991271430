.table {
  &.table-zebra tbody tr {
    @apply bg-base-100;
    &:nth-child(even) {
      @apply bg-light;
    }
  }

  :where(.table-pin-cols tr td[data-sticky=true]) {
    @apply text-sm text-base-content sticky bg-inherit;
  }

  thead {
    tr {
      @apply bg-slate-300 text-base-content font-bold text-sm;

      td {
        @apply cursor-grab;
        &.grabbing {
          @apply cursor-grabbing;
        }
      }
    }

    #select, #modifiers {
      width: 0;
      @apply whitespace-nowrap;
    }
  }

  tbody {
    tr {
      @apply h-full;
      &:hover {
        @apply bg-secondary #{!important};
        @apply text-light;
      }

      td {
        @apply p-0 h-full relative;
        a {
          @apply block box-border h-full;
          padding: 0.75rem 1rem;
        }

        .form-control {
          @apply h-full;
          label {
            @apply h-full justify-center;
          }
        }
      }
    }
  }
}