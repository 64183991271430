.steps {
  & .step {
    min-height: 3rem;

    &.fa {
      &:after {
        font-family: "Nerd", serif;
      }

      &-solid {
        &:after {
          font-weight: 900;
        }
      }
    }

    &:after {
      @apply text-light shadow-md;
    }

    &:before {
      @apply bg-slate-200;
    }

    &[data-content]:not(.step-neutral) + .step:not(.step-neutral):before {
      @apply bg-base-300;
    }

    &[data-content].step-primary + .step.step-primary:before,
    &[data-content].step-primary + .step.step-secondary:before,{
      @apply bg-primary;
    }

    &[data-text-xs=true]:after {
      @apply text-xs;
    }

    &:not([data-content]) {
      &:after {
        @apply animate-pulse bg-base-200;
        content: '';
      }

      &:before {
        @apply animate-pulse;
      }
    }

    &.step-neutral {
      &:after {
        @apply bg-slate-200 text-base-200;
      }

      &:before {
        @apply bg-slate-200;
      }

      @apply text-base-200;
    }

    &.force:hover {
      &:after {
        @apply bg-error cursor-pointer;
      }

      &:has(+ .step.step-secondary) {
        &:after {
          font-family: "Nerd", serif;
          font-weight: 900;
          content: '';
        }
      }
    }

    &.step-secondary + .force:hover {
      &:after {
        @apply text-white;
        font-family: "Nerd", serif;
        font-weight: 900;
        content: '';
      }
    }
  }
}