.smallToolTip {
  position: relative;
}

.smallToolTip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
}

.smallToolTip:hover .tooltiptext {
  visibility: visible;
}