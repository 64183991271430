// Thin
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Thin.ttf');
}

// Thin Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-ThinItalic.ttf');
}
// Extra Light
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-ExtraLight.ttf');
}

// Extra Light Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf');
}


// Light
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Light.ttf');
}

// Light Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-LightItalic.ttf');
}

// Normal
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf');
}

// Normal Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Italic.ttf');
}

// Medium
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf');
}

// Medium Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-MediumItalic.ttf');
}

// Semi Bold
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

// Semi Bold Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf');
}

// Bold
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Bold.ttf');
}

// Bold Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-BoldItalic.ttf');
}

// Extra Bold
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-ExtraBold.ttf');
}

// Extra Bold Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf');
}

// Black
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-Black.ttf');
}

// Black Italic
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../../assets/fonts/Poppins/Poppins-BlackItalic.ttf');
}